
.resume-template {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin
  : auto;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  }
  
  .resume-template h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
  }
  
  .resume-template p {
  margin-bottom: 1em;
  }
  
  .resume-template h2 {
  font-size: 1.5em;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 0.3em;
  margin-top: 1em;
  margin-bottom: 0.75em;
  }
  
  .resume-template h3 {
  font-size: 1.2em;
  margin-top: 0;
  }

  /* Loader.css */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black; /* Replace with your desired background color */
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of everything */
}

.loader-image {
  /* Add your loader image styles here */
  width: 100px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  animation: pulse 3s ease-in-out infinite; /* Custom animation */
}


@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.6;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.6;
  }
}

.loader-image:hover {
  /* Optional: Add hover effect for the loader image */
  transform: scale(1.2); /* Increase size on hover */
}

.hero-content {
  background-size: cover;
  background-position: center;
}

/* Adjust background size and position for smaller screens (e.g., mobile) */
@media (max-width: 768px) {
  .hero-content {
    background-size: contain; /* You can use 'contain' or 'cover' as needed */
    background-position: top; /* Adjust as needed */
  }
}